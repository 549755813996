import {ElementType, forwardRef, PropsWithChildren, useContext} from "react";
import {css, SerializedStyles, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {elevation} from "@pg-design/elevation";
import {calculateRemSize as crs} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons";
import {Text, TextVariant, TextVariantUnionType} from "@pg-design/text";
import {useIsMobile} from "@pg-mono/hooks";

import {ICoreModalProps, ModalCore} from "./ModalCore";
import {IHeaderVariant, ISystemModalTheme, ISystemModalVariant, SystemModalContext, SystemModalContextProvider} from "./SystemModalContext";

interface ISystemModalProps extends Omit<ICoreModalProps, "contentStyle"> {
    variant?: ISystemModalVariant;
    systemTheme?: ISystemModalTheme;
    headerVariant?: IHeaderVariant;
    zIndex?: number;
    closeButtonStyle?: SerializedStyles;
}

/**
 * @deprecated
 */
export const SystemModal = (props: PropsWithChildren<ISystemModalProps>) => {
    const {children, variant, systemTheme, headerVariant, zIndex = 9000, closeButtonStyle, ...restProps} = props;
    const theme = useTheme();
    const isMobile = useIsMobile();

    const isDarkTheme = systemTheme === "dark";
    const systemModalVariant = variant ? variant : "compact";

    return (
        <SystemModalContextProvider
            value={{variant: systemModalVariant, systemTheme: systemTheme ? systemTheme : "light", headerVariant: headerVariant ? headerVariant : "inherit"}}
        >
            <ModalCore
                closeButton={(closeModal) => (
                    <CloseButton closeModal={closeModal} systemModalVariant={systemModalVariant} isDarkTheme={isDarkTheme} css={closeButtonStyle} />
                )}
                contentStyle={[
                    css`
                        border-radius: 0;
                        background-color: ${isDarkTheme ? theme.colors.secondary : "#fff"};
                        padding: 0;
                        border: 0;
                        @media (max-width: ${theme.breakpoints.sm}) {
                            inset: 0;
                        }
                        ${!isMobile && [
                            elevation(1),
                            css`
                                border-radius: 1.6rem;
                            `
                        ]}
                    `
                ]}
                overlayStyle={css`
                    z-index: ${zIndex};
                    display: flex;
                    justify-content: center;
                    align-items: center;
                `}
                {...restProps}
            >
                {children}
            </ModalCore>
        </SystemModalContextProvider>
    );
};

interface ICloseButtonProps {
    closeModal: ICoreModalProps["onModalClose"];
    systemModalVariant: ISystemModalVariant;
    isDarkTheme: boolean;
    className?: string;
}

const CloseButton = (props: ICloseButtonProps) => {
    const {closeModal, systemModalVariant, isDarkTheme, className} = props;
    const theme = useTheme();
    const {headerVariant} = useContext(SystemModalContext);

    const arrowSize = headerVariant === "gray" ? "2.0" : "2.4";
    const wrapperSize = headerVariant === "gray" ? "4.8" : "2.0";
    const wrapperColor = headerVariant === "gray" ? theme.colors.gray[200] : "transparent";

    return (
        <CloseButtonStyled onClick={closeModal} variant={systemModalVariant} headerVariant={headerVariant} className={className}>
            <CloseIcon
                fill={isDarkTheme ? "#fff" : theme.colors.secondary}
                size={arrowSize}
                wrapperSize={wrapperSize}
                wrapperColor={wrapperColor}
                wrapperType="square"
            />
        </CloseButtonStyled>
    );
};

const CloseButtonStyled = styled.div<{variant: ISystemModalVariant; headerVariant?: IHeaderVariant}>`
    ${({variant, headerVariant}) => css`
        position: absolute;
        inset: ${getCloseButtonInset(variant, headerVariant)};
        cursor: pointer;
    `}
`;

function getCloseButtonInset(variant: ISystemModalVariant, headerVariant?: IHeaderVariant) {
    if (headerVariant && headerVariant === "gray") {
        return `0 0 auto auto`;
    }

    if (variant === "fit") {
        return `${crs(3)} ${crs(1.5)} auto auto`;
    }

    if (variant === "medium") {
        return `${crs(4)} ${crs(3)} auto auto`;
    }

    return "2rem 2rem auto auto";
}

/*
    Header
 */
interface ISystemModalHeaderProps extends PropsWithChildren {
    className?: string;
    as?: ElementType;
    headerTextCss?: SerializedStyles;
}

SystemModal.Header = forwardRef<HTMLDivElement, ISystemModalHeaderProps>((props, ref) => {
    const {children, className, as, headerTextCss} = props;
    const isMobile = useIsMobile();
    const {variant, systemTheme, headerVariant} = useContext(SystemModalContext);

    const isMobileCompact = isMobile && variant === "compact";

    return (
        <HeaderWrap variant={variant} isMobile={isMobile} isDark={systemTheme === "dark"} headerVariant={headerVariant} className={className} ref={ref}>
            <div>
                <HeaderText
                    as={as ? as : "h3"}
                    variant={getHeaderTextVariant(isMobileCompact, headerVariant)}
                    headerVariant={headerVariant}
                    css={headerTextCss}
                >
                    {children}
                </HeaderText>
            </div>
        </HeaderWrap>
    );
});

const HeaderWrap = styled.div<{variant: ISystemModalVariant; isMobile: boolean; isDark: boolean; headerVariant?: IHeaderVariant}>`
    ${({theme, isDark, variant, isMobile, headerVariant}) => css`
        color: ${isDark ? "#fff" : theme.colors.secondary};
        padding: ${getHeaderPadding(variant, isMobile, headerVariant)};
        margin: ${getHeaderMargin(variant, isMobile, headerVariant)};
        background: ${headerVariant && headerVariant === "gray" ? theme.colors.gray[100] : "none"};
    `}
`;

const HeaderText = styled(Text)<{headerVariant?: IHeaderVariant}>`
    ${({headerVariant}) =>
        headerVariant && headerVariant === "gray"
            ? css`
                  height: ${crs(6)};
                  display: flex;
                  align-items: center;
              `
            : ""}
`;

function getHeaderTextVariant(isMobileCompact: boolean, headerVariant?: IHeaderVariant): TextVariantUnionType {
    if (headerVariant && headerVariant === "gray") {
        return TextVariant.BODY_COPY_2;
    }

    if (isMobileCompact) {
        return TextVariant.HEADLINE_4;
    }

    return TextVariant.HEADLINE_3;
}

function getHeaderPadding(variant: ISystemModalVariant, isMobile: boolean, headerVariant?: IHeaderVariant) {
    if (variant === "fit" && headerVariant && headerVariant === "gray") {
        return `0 ${crs(1.5)}`;
    }

    if (variant === "fit") {
        return `${crs(3)} ${crs(1.5)} 0 ${crs(1.5)}`;
    }

    if (variant === "medium") {
        return `${crs(4)} ${crs(3)} 0 ${crs(3)}}`;
    }

    if (variant === "spacious" && isMobile) {
        return "2.05rem 5.2rem 0 2rem";
    }

    if (variant === "spacious") {
        return "2.95rem 5.2rem 0 2rem";
    }

    return "2rem 5.2rem 0 2rem";
}

function getHeaderMargin(variant: ISystemModalVariant, isMobile: boolean, headerVariant?: IHeaderVariant) {
    if (variant === "fit" && headerVariant && headerVariant === "gray") {
        return `0 0 ${crs(2)} 0`;
    }

    if (variant === "fit") {
        return `0 0 ${crs(1)} 0`;
    }

    if (variant === "medium") {
        return `0 0 ${crs(3)} 0`;
    }

    if (variant === "spacious") {
        return "0 0 2.8rem 0";
    }

    if (isMobile) {
        return "0 0 0.45rem 0";
    }

    return "0 0 1.95rem 0";
}

/*
    Content
 */
interface ISystemModalContentProps extends PropsWithChildren {
    fullWidth?: boolean;
    className?: string;
    textCss?: SerializedStyles;
    as?: ElementType;
}

SystemModal.Content = (props: ISystemModalContentProps) => {
    const {children, fullWidth, className, as, textCss} = props;
    const isMobile = useIsMobile();
    const {variant, systemTheme} = useContext(SystemModalContext);

    return (
        <ContentWrap variant={variant} isDark={systemTheme === "dark"} isMobile={isMobile} fullWidth={fullWidth} className={className} as={as}>
            <Text as="div" variant="body_copy_2" className="text-wrap" css={textCss}>
                {children}
            </Text>
        </ContentWrap>
    );
};

const ContentWrap = styled.div<{variant: ISystemModalVariant; isMobile: boolean; isDark: boolean; fullWidth?: boolean}>`
    ${({theme, variant, isMobile, isDark, fullWidth}) => css`
        color: ${isDark ? "#fff" : theme.colors.secondary};
        padding: ${getContentWrapPadding(variant, isMobile, fullWidth)};
    `}
`;

function getContentWrapPadding(variant: ISystemModalVariant, isMobile: boolean, fullWidth?: boolean) {
    if (variant === "medium") {
        return `0 ${crs(3)} ${crs(5)} ${crs(3)}`;
    }

    if (variant === "spacious" && fullWidth && isMobile) {
        return "0 2rem 2.05rem 2rem";
    }

    if (variant === "spacious" && fullWidth) {
        return "0 2rem 2.95rem 2rem";
    }

    if (variant === "spacious" && isMobile) {
        return "0 5.2rem 2.05rem 2rem";
    }

    if (variant === "spacious") {
        return "0 5.2rem 2.95rem 2rem";
    }

    if (variant === "fit") {
        return `0 ${crs(1.5)} ${crs(3)} ${crs(1.5)}`;
    }

    if (fullWidth) {
        return "0 2rem 2rem 2rem";
    }

    return "0 5.2rem 2rem 2rem";
}
